<template>
    <div class="top-container">
        <div class="top-wrap">
            <div class="top-cell">
                <!-- <date-range-picker 
                    @date-picked="handleDatePicked"
                    size="sm"
                /> 
                -->
                
                <el-date-picker
                    size="small"
                    v-model="operationRangeTime"
                    type="monthrange"
                    align="center"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始月份"
                    end-placeholder="结束月份"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :default-time="['00:00:00', '23:59:59']"
                    :picker-options="pickerOptions"
                    @change="handleChangeMonthrange"
                    >
                    <!-- :default-value="['2022-01-12', '2022-04-12']" -->
                </el-date-picker>

                <template v-if="filterIndex == 0">
                    <el-radio-group 
                        v-model="myOrAllRadio" 
                        @change="handleChangeRadio"
                        class="my-radio">
                        <el-radio :label="0">只看我的</el-radio>
                        <el-radio :label="1">查看全部</el-radio>
                    </el-radio-group>
                </template>
                
            </div>

            <div class="filter-area">
                <div class="filter-left-text">筛选条件：</div>
                <div 
                    class="filter-cell"
                    v-for="(item, index) in filterStore"
                    :class="{'hot-light': aCurrentLabel.includes(item)}"
                    :key="'floatingFilter_' + index"
                    v-show="headerFilter[tableTitleMap[item].name].length>0"
                    >
                    <!-- v-show="headerFilter[tableTitleMap[item].name].length>0"
                    :enableSearch="tableTitleMap[item].headerSelectEnableSearch"
                    :defaultSelectedItems="tableTitleMap[item].hasDefaultSelectedItems ? defaultSelect[tableTitleMap[item].name] : []" 
                    :selectItem="headerFilter[tableTitleMap[item].name]"
                    -->
                    <!-- :enableCount="true" -->
                    <!-- :enableCount="tableTitleMap[item].enableCount" -->
                    <select-popover
                        :maxSelect="5"
                        :title="tableTitleMap[item].label"
                        :label="tableTitleMap[item].name"
                        :enableSearch="tableTitleMap[item].headerSelectEnableSearch"
                        :selectItem="headerFilter[tableTitleMap[item].name]"
                        :enableCount="true"
                        @selectChange="selectDataChange"
                    ></select-popover>
                </div>
            </div>
        </div>

        <slot :chartsData="chartsData" :loadingCharts="loadingCharts"></slot>

        <div class="select-container">
            <span>共
                <span class="color-orange">{{total}}</span>
                位{{filterIndex == 0 ? '候选人': '顾问'}}
            </span>
           
            <div class="select-tag-list">
                <div class="select-tag" 
                    v-for="(item, tag) in labelList" 
                    :key="tag" 
                    v-show="item.length > 0">
                    <el-tooltip
                        placement="top-start"
                        effect="light"
                        popper-class="label-tip">
                        <div slot="content">{{ item | labelsTip }}</div>
                        <div class="text-ellipsis">
                            <span>{{ searchParamTitle[tag] }} :</span>
                            <span class="content" v-for="(param, index) in item" :key="index">
                                {{ param.label }}
                                <span v-if="item.length > 0 && index !== (item.length - 1)">,</span>
                            </span>
                        </div>
                    </el-tooltip>
                    <span class="btn-close" @click="clearSelect(tag)"></span>
                </div>
            </div>
            
            <table-title
                v-if="filterIndex == 0"
                class="floating-list-table-title"
                start-pos="3"
                :title-store="titleStore"
                :table-title="tableTitleJson"
                @table-title="handleTableTitle"
                />

        </div>
    </div>
</template>

<script>
import moment from 'moment';

import DateRangePicker from '#/component/common/date-range-picker.vue';
import TableTitle from '#/component/common/tableTitle.vue';
import SelectPopover from '#/component/common/select-popover.vue';

import operationTableTitle, {tableTitleMap, dataTableTitleMap} from '#/js/config/operationPlatformTitle.js';

export default {
    name: 'top-wrap',
	components: {
        // DateRangePicker,
        TableTitle,
        SelectPopover,
	},
    props: {
        total: {
            type: Number,
            default: 0,
        },
        filterIndex: {
            type: Number,
            default: 0,
        },
        filterStore: {
            type: Array,
            default: () => [],
        },
        titleStore: {
            type: Array,
            default: () => [],
        },
        headerFilter: {
            type: Object,
            default: () => {},
        },
        defaultMonth: {
            type: Array,
            default: () => [],
        },
        // 饼图数据
        chartsData: {
            type: Object,
            default: () => {},
        },
        loadingCharts: {
            type: Boolean,
            default: false,
        },
    },
	data() {
		return {
            myOrAllRadio: 1,
            // filterStore: this.filterIndex == 0 ? ['receiverName', 'jobCategoryName', 'businessTags']: ['receiverName', 'jobCategoryName'],

            aCurrentLabel: [],
            selectData: [],
            tableTitleMap: this.filterIndex == 0 ? tableTitleMap: dataTableTitleMap,
            
            tableTitleJson: operationTableTitle,

            searchParamTitle: {
                receiverName: '认领人',
                jobCategoryName: '赛道',
                businessTags: '标签',
            },
            labelList: {
                // labelList 内标准格式为{
                //     text: 'xxxx',
                //     .......
                // }
                receiverName: [],
                jobCategoryName: [],
                businessTags: [],
            },

            operationRangeTime: this.defaultMonth,
            pickerOptions: {
                // TODO:上线前放开下面这一行
                disabledDate: this.disabledDate,

                // shortcuts: [
                //     {
                //         text: '本月',
                //         onClick(picker) {
                //             picker.$emit('pick', [new Date(), new Date()]);
                //         }
                //     }, 
                //     {
                //         text: '今年至今',
                //         onClick(picker) {
                //             const end = new Date();
                //             const start = new Date(new Date().getFullYear(), 0);
                //             picker.$emit('pick', [start, end]);
                //         }
                //     }, 
                //     {
                //         text: '最近六个月',
                //         onClick(picker) {
                //             const end = new Date();
                //             const start = new Date();
                //             start.setMonth(start.getMonth() - 6);
                //             picker.$emit('pick', [start, end]);
                //         }
                //     },
                // ],
            },
        };
    },
    computed: {
        userInfo() {
            return this.$store.state.user.userInfo;
        },
        userId() {
            return this.userInfo.id;
        },
    },
    filters: {
        labelsTip(labels) {
            return labels.map(item => item.label).join(",");
        }
    },
    created() {

    },
	mounted() {
		
	},
	methods: {
        
        disabledDate(date) {
            const lastMonth = moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD HH:mm:ss"),
                currentMonth = moment().endOf('month').format("YYYY-MM-DD HH:mm:ss"),
                pre2Month = moment().subtract(3, 'months').endOf('month').format("YYYY-MM-DD HH:mm:ss"),
                pre3Month = moment().subtract(4, 'months').endOf('month').format("YYYY-MM-DD HH:mm:ss"),
                pre6Month = moment().subtract(6, 'months').startOf('month').format("YYYY-MM-DD HH:mm:ss");
            // 平台运营
            if(this.filterIndex == 0) {
                return moment(date) < moment(pre6Month) || moment(date) > moment(pre3Month);
            } else {
                // 运营数据
                // return moment(date) > moment(currentMonth); 如果放开4月请放开这一行代码
                return moment(date) > moment(lastMonth);
            }
        },
        // 时间range
        handleChangeMonthrange(val) {
            if(!this.operationRangeTime) return;
            if(this.operationRangeTime?.length < 2) return;
            const params = this.generateParams();
            this.$emit('top-data-change-cb', params, true);
        },

        // 我的还是全部
        handleChangeRadio() {
            const params = this.generateParams();
            this.$emit('top-data-change-cb', params, true);
        },

        // 筛选
        selectDataChange(label, selectData) {
            this.labelList[label] = selectData;

            const params = this.generateParams();
            this.$emit('top-data-change-cb', params, true);
        },

        generateParams() {
            const params = {
                startDate: this.operationRangeTime[0],
                endDate: moment(this.operationRangeTime[1]).endOf('month').format("YYYY-MM-DD HH:mm:ss"),
                userId: this.myOrAllRadio == 0 ? this.userId: '',

                receiverIds: this.labelList['receiverName'].map(item => item.itemValue),
                jobCategoryIds: this.labelList['jobCategoryName'].map(item => item.itemValue),
                businessTagIds: this.labelList['businessTags'].map(item => item.itemValue),
            };
            return params;
        },
        clearSelect(tag) {
            this.$set(this.labelList, tag, []);
            const params = this.generateParams();

            this.$emit('clean-head-filter', tag);
            this.$emit('top-data-change-cb', params, true);
        },

        // 表头改变后回调
        handleTableTitle(val) {
            this.$emit('title-change-cb', val);
        },
    }
}
</script>
<style lang="scss" scope>
.top-container{
    .top-wrap{
        background: white;
        box-sizing: border-box;
        border-radius: 6px;
        padding: 0 20px;
        .top-cell{
            box-sizing: border-box;
            border-bottom: 1px dashed #ccc;
            padding: 10px 0;
            .my-radio{
                margin-left: 40px;
            }
            .el-range-separator{
                width: 20px;
            }
        }
        .filter-area{
            // border-top: 1px dashed #CCC;
            // box-sizing: border-box;
            // padding-top: 10px;
            // margin-top: 10px;
            box-sizing: border-box;
            padding: 10px 0;
            .filter-left-text{
                display: inline-block;
                font-size: 14px;
                font-weight: 400;
                color: #999999;
            }
            .filter-cell{
                display: inline-block;
                margin-right: 20px;
            }
            .hot-light{
                color: #38BC9D;
            }
            &.no-top-border{
                border-top: 1px dashed transparent;
            }
        }
    }

    .select-container {
        margin-top: 16px;
        margin-bottom: 6px;
        line-height: 19px;
        position: relative;
        padding-right: 40px;
        &-point-tip {
            margin-left: 10px;
            font-size: 14px;
            color: #EE6E4F;
            line-height: 28px;
            float: left;
        }
        > span:first-child {
            float: left;
            line-height: 28px;
        }

        .select-tag-list {
            display: inline;
            margin-left: 10px;
            .select-tag {
                display: inline-block;
                position: relative;
                margin-right: 10px;
                padding: 0 28px 0 8px;
                max-width: 486px;
                height: 28px;
                line-height: 28px;
                color: #999999;
                background: #F8F8F8;
                border: 1px solid #DDDDDD;
                border-radius: 4px;
                &:last-child {
                    margin-right: 0;
                }
                .btn-close {
                    float: right;
                    position: absolute;
                    right: 10px;
                    top: 0;
                    &:after {
                        display: inline-block;
                        content: '+';
                        float: right;
                        width: 14px;
                        height: 28px;
                        transform: rotate(-45deg);
                        font-size: 20px;
                        text-align: center;
                        line-height: 26px;
                        cursor: pointer;
                    }
                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
        // 再次操作确认进入面试列表相关代码
        .show-confirm-list {
            position: absolute;
            top: 0;
            right: 40px;
        }
        .show-confirm-list-btn {
            background-color: #fff;
            border-color: #ccc;
            color: #666;
            position: relative;
            z-index: 1000;
            &:hover {
                background-color: #48CAAC;
                border-color: #48CAAC;
                color: #fff;
            }
        }
        .show-confirm-list-guide {
            position: absolute;
            bottom: 40px;
            left: -72px;
            z-index: 1000;
            width: 254px;
            height: 64px;
            border-radius: 6px;
            background-color: #fff;
            color: #333;
            font-size: 16px;
            text-align: center;
            line-height: 64px;
            &::before {
                content: "";
                position: absolute;
                bottom: -10px;
                left: 136px;
                width: 10px;
                height: 10px;
                background-color: #fff;
                transform: rotate(-45deg);
                transform-origin: 0 0;
            }
            .hide-guide-btn {
                position: absolute;
                right: 6px;
                top: 6px;
                font-size: 14px;
                font-weight: bold;
                color: #999;
                cursor: pointer;
                &:hover {
                    color: #333;
                }
            }
        }
        .show-confirm-list-modal {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            z-index: 999;
            background-color: rgba(0, 0, 0, 0.4);
        }
        // 再次操作确认进入面试列表相关代码 --end
        .floating-list-table-title{
            position: absolute;
            top: 5px;
            right: 0px;
        }
    }
}
</style>