const operationTableTitle = [
    {
        value: "candidateName",
        name: "候选人姓名",
        disabled: true
    },
    {
        value:"company",
        name:"公司",
        disabled:true
    },
    {
        value:"title",
        name:"职位",
        disabled:true
    },
    {
        value:"recommendedAt",
        name:"推荐时间",
        disabled:false
    },
    {
        value:"customerName",
        name:"推荐至公司",
        disabled:false
    },
    {
        value:"jobName",
        name:"推荐至职位",
        disabled:false
    },
    {
        value:"jobCategoryName",
        name:"所在赛道",
        disabled:false
    },
    {
        value:"recommendationStatus",
        name:"当前状态",
        disabled:false
    },
    {
        value:"ownerName",
        name:"推荐顾问",
        disabled:false
    },
    {
        value:"candidateCreator",
        name:"简历创建者",
        disabled:false
    },
    {
        value:"receiverName",
        name:"认领人",
        disabled:false
    },
    {
        value:"businessTags",
        name:"标签",
        disabled:false
    },
    {
        value:"followRecordsCount",
        name:"跟进详情",
        disabled:false
    },
];
export const dataTableTitleMap = {
    "jobCategoryName": {
        name: "jobCategoryName",
        label: "所在赛道",
        labelClassName: "",
        isSimpleText: false,
        isHeaderSelect: false,
        minWidth: "106",
        customClass: "",
        headerSelectEnableSearch: true,
        showOverflowTooltip: true,
        enableCount: false, // 赛道没有数据
    },
    "receiverName": {
        name: "receiverName",
        label: "认领人",
        labelClassName: "",
        isSimpleText: false,
        isHeaderSelect: false,
        minWidth: "106",
        customClass: "",
        headerSelectEnableSearch: true,
        showOverflowTooltip: true,
        enableCount: true,
    },
};
export const tableTitleMap = {
        "recommendedAt": {
            name: "recommendedAt",
            label: "推荐时间",
            labelClassName: "",
            isSimpleText: false,
            isHeaderSelect: false,
            minWidth: "146",
            customClass: "",
            showOverflowTooltip: true
        },
        "customerName": {
            name: "customerName",
            label: "推荐至公司",
            labelClassName: "",
            isSimpleText: false,
            isHeaderSelect: false,
            minWidth: "106",
            customClass: "",
            showOverflowTooltip: true
        },
        "jobName": {
            name: "jobName",
            label: "推荐至职位",
            labelClassName: "",
            isSimpleText: false,
            isHeaderSelect: false,
            minWidth: "106",
            customClass: "",
            showOverflowTooltip: true
        },
        "jobCategoryName": {
            name: "jobCategoryName",
            label: "所在赛道",
            labelClassName: "",
            isSimpleText: false,
            isHeaderSelect: false,
            minWidth: "106",
            customClass: "",
            headerSelectEnableSearch: true,
            showOverflowTooltip: true,
            enableCount: false, // 赛道没有数据
        },
        "recommendationStatus": {
            name: "recommendationStatus",
            label: "当前状态",
            labelClassName: "",
            isSimpleText: false,
            isHeaderSelect: false,
            minWidth: "106",
            customClass: "",
            showOverflowTooltip: true
        },
        "ownerName": {
            name: "ownerName",
            label: "推荐顾问",
            labelClassName: "",
            isSimpleText: false,
            isHeaderSelect: false,
            minWidth: "106",
            customClass: "",
            showOverflowTooltip: true
        },
        "candidateCreator": {
            name: "candidateCreator",
            label: "简历创建者",
            labelClassName: "",
            isSimpleText: false,
            isHeaderSelect: false,
            minWidth: "106",
            customClass: "",
            showOverflowTooltip: true
        },
        "receiverName": {
            name: "receiverName",
            label: "认领人",
            labelClassName: "",
            isSimpleText: false,
            isHeaderSelect: false,
            minWidth: "106",
            customClass: "",
            showOverflowTooltip: true,
            enableCount: true,
            headerSelectEnableSearch: true,
        },
        "businessTags": {
            name: "businessTags",
            label: "标签",
            labelClassName: "",
            isSimpleText: false,
            isHeaderSelect: false,
            minWidth: "106",
            customClass: "",
            showOverflowTooltip: true,
            enableCount: true,
            headerSelectEnableSearch: true,
        },
        "followRecordsCount": {
            name: "followRecordsCount",
            label: "跟进详情",
            labelClassName: "",
            isSimpleText: false,
            isHeaderSelect: false,
            minWidth: "126",
            customClass: "",
            showOverflowTooltip: true
        },
}

export default operationTableTitle;
