import { operation_platform as operationUrl } from '#/js/config/api.json';
import { operationPlatform as javaOperationUrl } from '#/js/config/javaApi.json';

export default {
    // 认领人
    getReceivers(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: operationUrl.get_receivers,
            data: params
        });
    },
    // 赛道
    getSnapshotJobCategories(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: operationUrl.get_snapshot_job_categories,
            data: params
        });
    },
    // 标签
    getSnapshotTages(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: operationUrl.get_snapshot_tages,
            data: params
        });
    },
    // 平台运营列表数据
    getSnapshotList(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: operationUrl.get_snapshot_list,
            data: params
        });
    },
    // 批量认领
    batchAcceptCandidate(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: operationUrl.batch_accept_candidate,
            data: params
        });
    },

    // 获取有运营权限的人
    getFirmPlatformOperationers(params) {
        return _request({
            method: "GET",
            baseURL: "LbdOpenApi",
            url: operationUrl.firm_platform_operationers,
            data: params
        });
    },
    
    // 转移操作
    candidateSnapshotTransfer(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: operationUrl.candidate_snapshot_transfer,
            data: params
        });
    },



    // 标签列表
    getTagList(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: operationUrl.get_tag_list,
            data: params
        });
    },
    // 标签类型列表
    getTagCategoryList(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: operationUrl.get_tag_category_list,
            data: params
        });
    },
    // 添加标签
    tagAdd(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: operationUrl.tag_add,
            data: params
        });
    },
    // 添加标签类别
    tagAddCategory(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: operationUrl.tag_add_category,
            data: params
        });
    },
    
    // 编辑分类或者标签
    updateCategoryOrTag(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: operationUrl.update_category_or_tag,
            data: params
        });
    },

    // 绑定
    tagBind(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: operationUrl.tag_bind_tag,
            data: params
        });
    },

    // 解绑
    tagUnbind(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: operationUrl.tag_unbind_tag,
            data: params
        });
    },

    // 批量绑定
    tagBatchBindTag(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: operationUrl.tag_batch_bind_tag,
            data: params
        });
    },

    // 删除标签
    batchTagDelete(params) {
        return _request({
            method: "DELETE",
            baseURL: "LbdOpenApi",
            url: operationUrl.batch_tag_delete,
            data: params,
        });
    },
    // 删除分类
    tagDeleteCategory(params) {
        return _request({
            method: "DELETE",
            baseURL: "LbdOpenApi",
            url: operationUrl.tag_delete_category,
            data: params,
        });
    },
    // 批量移动标签
    batchMoveTag(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: operationUrl.tag_batch_move_tag,
            data: params,
        });
    },

    
    // 职位列表
    getMyPublishJobs(params) {
        return _request({
            method: "GET",
            baseURL: "LbdOpenApi",
            url: operationUrl.get_my_publish_jobs,
            data: params
        });
    },
    // 根进列表
    getFollowRecords(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: operationUrl.get_follow_records,
            data: params
        });
    },
    // 添加根进
    addFollowRecord(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: operationUrl.add_follow_record,
            data: params
        });
    },
    // 编辑根进
    updateFollowRecord(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: operationUrl.update_follow_record,
            data: params
        });
    },
    // 删除根进
    deleteFollowRecord(id, params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: operationUrl.delete_follow_record.replace('%p', id),
            data: params
        });
    },

    // 获取运营数据报表
    getReportReceivers(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: operationUrl.get_report_receivers,
            data: params
        });
    },
    getReportCategories(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: operationUrl.get_report_categories,
            data: params
        });
    },

    getMultiReport(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: operationUrl.get_candidate_snapshot_report,
            data: params,
        });
    },
    getSnapshotDetailReport(params) {
        return _request({
            method: "POST",
            baseURL: "LbdOpenApi",
            url: operationUrl.get_snapshot_detail_report,
            data: params,
        });
    },

    getLexiconText(params) {
        return _request({
            method: "GET",
            baseURL: "LbdJavaApi",
            javaProject: 'performance',
            url: javaOperationUrl.get_es_words,
            data: params,
        });
    },
    
    
};